import React, { useEffect } from 'react';
import L from 'leaflet';
import { } from 'mapbox-gl-leaflet';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'leaflet/dist/leaflet.css';

import "../../../../../node_modules/leaflet.markercluster/dist/leaflet.markercluster-src";
import '../../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.css';
import '../../../../../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css';
import FotosHelper from "../../helpers/FotosHelper";
import map from 'lodash/map';

export default function MapBoxListado(props) {
    let mapContainer;
    useEffect(() => {

        const map = L.map(mapContainer, { attributionControl: true, maxZoom: 20} )
        const mapStyle = 'https://maps.geoapify.com/v1/styles/osm-bright/style.json';

        L.mapboxGL({
            style: `${mapStyle}?apiKey=${process.env.MIX_GEOAPIFY_KEY}`,
            zoomOffset: -1,
        }).addTo(map);

        var markers = new L.MarkerClusterGroup();
        populate(markers, props.propiedades);

        map.addLayer(markers);
        map.fitBounds(markers.getBounds());

        map.attributionControl.setPrefix('Powered by <a rel="nofollow" href="https://www.geoapify.com/" target="_blank">Geoapify</a> | © OpenStreetMap <a rel="nofollow" href="https://www.openstreetmap.org/copyright" target="_blank">contributors</a>')

    }, [mapContainer]);

    const populate = (markers, propiedades) => {
        var markersList = [];
        map(propiedades, (propiedad) => {
            var m = new L.Marker([propiedad.lat, propiedad.lng], { icon: FotosHelper.markerAA(L) }).on('click', () => props.onClickMarker(propiedad.referencia));
            markersList.push(m);
            markers.addLayer(m);
        });
        return false;
    }

    return (
        <div className="mapbox container">
            <div style={props.style} id="mapListado" ref={el => mapContainer = el}></div>
        </div>
    );
}